import React from "react"
import FeedbackForm from "../components/headerAndFooter/FeedbackForm"
import Layout from "../components/utils/layout"
import SEO from "../components/utils/seo"
import dots from "../images/bgs/dots.svg"

const ContactUs = () => (
  <Layout image={dots} full>
    <SEO title="Contact Us" />
    <FeedbackForm />
  </Layout>
)

export default ContactUs
